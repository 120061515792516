@font-face {
  font-family: 'Brain Storming Arial Rounded MT Bold';
  src: local('Brain Storming Arial Rounded MT Bold'),
    url('./assets/fonts/ARLRDBD.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'コーポレートロゴ・M';
  src: local('コーポレートロゴ・M'),
    url('./assets/fonts/Corporate-Logo-Medium-ver2.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'BergamoStd';
  src: local('BergamoStd'),
    url('./assets/fonts/BergamoStd/BergamoStd.otf') format('truetype');
}
@font-face {
  font-family: 'Cousine';
  src: local('Cousine'),
    url('./assets/fonts/Cousine/Cousine.ttf') format('truetype');
}
@font-face {
  font-family: 'Dustismo';
  src: local('Dustismo'),
    url('./assets/fonts/Dustismo/Dustismo.ttf') format('truetype');
}
@font-face {
  font-family: 'GentiumBasic';
  src: local('GentiumBasic'),
    url('./assets/fonts/GentiumBasic/GentiumBasic.ttf') format('truetype');
}
@font-face {
  font-family: 'Kirsty';
  src: local('Kirsty'),
    url('./assets/fonts/Kirsty/Kirsty.ttf') format('truetype');
}
@font-face {
  font-family: 'KomikaHand';
  src: local('KomikaHand'),
    url('./assets/fonts/KomikaHand/KomikaHand.ttf') format('truetype');
}
@font-face {
  font-family: 'LiberationMono';
  src: local('LiberationMono'),
    url('./assets/fonts/LiberationMono/LiberationMono.ttf') format('truetype');
}
@font-face {
  font-family: 'LiberationSans';
  src: local('LiberationSans'),
    url('./assets/fonts/LiberationSans/LiberationSans.ttf') format('truetype');
}
@font-face {
  font-family: 'LiberationSerif';
  src: local('LiberationSerif'),
    url('./assets/fonts/LiberationSerif/LiberationSerif.ttf') format('truetype');
}
@font-face {
  font-family: 'Orbitron';
  src: local('Orbitron'),
    url('./assets/fonts/Orbitron/Orbitron.ttf') format('truetype');
}
@font-face {
  font-family: 'SFArchRival';
  src: local('SFArchRival'),
    url('./assets/fonts/SFArchRival/SFArchRival.ttf') format('truetype');
}
@font-face {
  font-family: 'SFCartoonistHand';
  src: local('SFCartoonistHand'),
    url('./assets/fonts/SFCartoonistHand/SFCartoonistHand.ttf')
      format('truetype');
}
@font-face {
  font-family: 'SourceSansPro';
  src: local('SourceSansPro'),
    url('./assets/fonts/SourceSansPro/SourceSansPro.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'コーポレートロゴ・M', 'Brain Storming Arial Rounded MT Bold',
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: Arial, Helvetica, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* NOTE: custom class names for custom blocksk draft-js */
.left .public-DraftStyleDefault-ltr {
  text-align: left;
}
.center .public-DraftStyleDefault-ltr {
  text-align: center;
}
.right .public-DraftStyleDefault-ltr {
  text-align: right;
}

.DraftEditor-root ul {
  padding-inline-start: 20px;
  margin-block-start: 0;
  list-style-position: inside;
  padding-left: 0;
}
.DraftEditor-root ul div {
  display: inline;
  word-break: break-all;
}
.SnackbarProviderMarginTop {
  margin-top: 35px;
}

.text-tiny {
  font-size: .7em;
}

.text-small {
  font-size: .85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}